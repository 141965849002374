import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedDate, setSelectedAppointment } from '../../services/slices/selectionsSlice';
import { setScheduleFormVisible, setEdit } from '../../services/slices/scheduleFormSlice';
import './weekview.css';
import Badge from 'react-bootstrap/Badge';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import ArrowButton from '../arrowBtn/arrowBtn';
import { refDateString } from "../../services/functions";
import { setEventsLoaded } from '../../services/slices/useEventsSlice';
import useGetEvents from '../../services/hooks/useGetEvents';

const WeekCalendar = ({ children }) => {
  const [persons, loaded,] = useGetEvents();
  const [toastVisible, setToastVisible] = useState(false);
  const selectedDate = useSelector((state) => state.selections.selectedDate);
  const loggedUser = useSelector((state) => state.selections.loggedUser);
  const dispatch = useDispatch();

  // Function to check if the appointment is on the same day and hour
  function isSameDayAndHour(appointment, utcDate, day, hour) {
    const dt = moment.utc(utcDate).local();
    return dt.format('D') === day && dt.format('H') === hour;
  }

  // Function to get the background color of the badge
  const getBadgeBackgroundColor = (event) => {
    if (event.creator === loggedUser.email) {
      return 'secondary';
    } else {
      return event.participants.includes(loggedUser.email) ? 'info' : 'warning';
    }
  };

  // Function to get the text color of the badge
  const getTextColor = (event) => {
    if (event.creator === loggedUser.email) {
      return 'light';
    } else {
      return 'dark';
    }
  };

  // Function to render the events grid
  const renderWeekDays = () => {
    const days = [];
    const startOfWeek = moment(selectedDate).startOf('week');
    const weekdaysPT = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];

    for (let i = 0; i < 7; i++) {
      const day = startOfWeek.clone().add(i, 'days');
      days.push(
        <div key={i} className="week-day">
          <div className="day-name">{weekdaysPT[day.day()]}</div>
          <div className="day-date">{day.format('D')}</div>
        </div>
      );
    }
    return days;
  };

  const renderEventsGrid = () => {
    const grid = [];
    const startOfWeek = moment(selectedDate).startOf('week');

    // Render the week days at the top
    grid.push(
      <div key="week-days" className="hour-row" style={{ zIndex: 1000 }}>
        <div className="hour-label"></div>
        {renderWeekDays()}
      </div>
    );

    for (let hour = 0; hour < 24; hour++) {
      const hourRow = [];
      for (let day = 0; day < 7; day++) {
        const currentDay = startOfWeek.clone().add(day, 'days');
        const eventsForDay = persons
          .map((person) => person.appointments)
          .flat()
          .filter((appointment) =>
            isSameDayAndHour(appointment, appointment.start_date, currentDay.format('D'), hour.toString())
          );
        hourRow.push(
          <div key={`${day}-${hour}`} className="event-cell">
            {eventsForDay.map((event) => (
              <Badge
                key={event.id}
                pill
                bg={getBadgeBackgroundColor(event)}
                text={getTextColor(event)}
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(setSelectedAppointment(event));
                  dispatch(setEdit(true));
                  dispatch(setScheduleFormVisible(true));
                }}
              >
                {event.description} 
              </Badge>
            ))}
          </div>
        );
      }
      grid.push(
        <div key={hour} className="hour-row">
          <div className="hour-label">{hour}:00</div>
          {hourRow}
        </div>
      );
    }
    return grid;
  };

  // Function to change the week view
  function changeWeek(delta) {
    const newDate = moment(selectedDate);
    newDate.add(delta, 'weeks');
    dispatch(setSelectedDate(newDate.format('YYYY-MM-DD HH:mm')));
    dispatch(setEventsLoaded(false));
  }

  return (
    <div className="d-flex flex-column w-100 h-99">
      <ToastContainer position="top-start" className="p-3 position-fixed top-0 mt-5">
        <Toast show={toastVisible} onClose={() => setToastVisible(false)}>
          <Toast.Header>
            <strong className="me-auto">Informação</strong>
          </Toast.Header>
          <Toast.Body>Não é possível marcar um evento novo no passado!</Toast.Body>
        </Toast>
      </ToastContainer>
      <div className="events-grid-container">{renderEventsGrid()}</div>
      {children}
    </div>
  );
};

export default WeekCalendar;
                 

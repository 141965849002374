import { useEffect, useState } from "react";
import NavBar from "../../components/navbar/navbar";
import ScheduleList from "../../components/scheduleList/scheduleList";
import Calendar from "../../components/calendar/calendar";
import UserList from "../../components/userList/userList";
import { useSelector, useDispatch } from 'react-redux'
import { setUserListVisible } from "../../services/slices/userListSlice";
import CalendarCompareOverlay from "../../components/calendarCompareOverlay/calendarCompareOverlay";
import useGetEvents from "../../services/hooks/useGetEvents";
import useGetUserProfile from "../../services/hooks/useGetUserProfile";
import ScheduleFormModal from "../../components/scheduleFormModal/scheduleFormModal";
import WeekCalendar from "../../components/calendar/weekview";

export default function Home({ utils }) {

    const [viewStyle, setViewStyle] = useState('day');

    const dispatch = useDispatch();
    const styleRedux = useSelector((state) => state.viewStyle.style);
    const [currentUser] = useGetUserProfile();
    const selectedDate = useSelector((state) => state.selections.selectedDate);
    const selectedUsers = useSelector((state) => state.selections.selectedUsers);
    const userListVisible = useSelector((state) => state.userList.userListVisible);
    const scheduleFormVisible = useSelector((state) => state.scheduleForm.visible);

    const [events, eventsLoaded,] = useGetEvents();

    useEffect(() => {
        setViewStyle(styleRedux);

    }, [styleRedux, selectedDate, dispatch, currentUser, userListVisible, eventsLoaded, events])

    function renderVisit() {
        if (selectedUsers.length > 0 && userListVisible === false && scheduleFormVisible === false) {
            return <CalendarCompareOverlay
                visible={true}
                goBack={undefined}
            />
        }
        return;
    }

    return (
        <div>
            <NavBar
                usersClick={() => dispatch(setUserListVisible(true))}
                iconView={viewStyle}
            />
            <div className="content-container h-100 w-100" style={{ marginTop: '65px' }}>
                <ScheduleFormModal
                    dateRef={selectedDate}
                    userRef={currentUser}
                    alertFunction={utils.showAlert}
                    hideAlert={utils.hideAlert}
                />
                <UserList />

                {viewStyle === 'month' ?
                    <WeekCalendar
                        children={renderVisit()}
                    />
                    :
                    <div>
                        <ScheduleList
                            children={renderVisit()}
                        />
                    </div>
                }
                <div>
                </div>

            </div>
        </div>

    )
}

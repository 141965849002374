import { useState, useEffect } from 'react';
import Badge from 'react-bootstrap/Badge';
import moment from 'moment';
import useGetEvents from '../../services/hooks/useGetEvents';
import { useSelector, useDispatch } from 'react-redux'
import { setSelectedDate, setSelectedAppointment } from '../../services/slices/selectionsSlice';
import { setScheduleFormVisible, setEdit } from '../../services/slices/scheduleFormSlice';
import './scheduleList.css';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import ArrowButton from '../arrowBtn/arrowBtn';
import { refDateString } from "../../services/functions";
import { setEventsLoaded } from '../../services/slices/useEventsSlice';

const ScheduleList = ({ children }) => {

  const [persons, loaded,] = useGetEvents();
  const [toastVisible, setToastVisible] = useState(false);
  const [subtitle, setSbtitle] = useState('Nenhum agendamento marcado');
  const selectedDate = useSelector((state) => state.selections.selectedDate);


  const loggedUser = useSelector((state) => state.selections.loggedUser);
  const dateRedux = useSelector((state) => state.selections.selectedDate);

  const dispatch = useDispatch();

  // a function that reads a UTC date string, loads in moment and checks if the hour and day are the same, considering the local time zone
  function isSameDayAndHour(appointment, utcDate, day, hour) {
    const dt = moment.utc(utcDate).local();
    const r = dt.format('D') === day && dt.format('H') === hour;
    return r;
  }

  const getBadgeBackgroundColor = (event) => {

    if (event.creator === loggedUser.email) {
      return 'secondary';
    } else {
      return event.participants.includes(loggedUser.email) ? 'info' : 'warning';
    }
  }

  const getTextColor = (event) => {
    if (event.creator === loggedUser.email) {
      return 'light';
    } else {
      return 'dark';
    }
  }


  useEffect(() => {
    if (persons) {
      const ev = persons.map((event) => { return event.appointments }).flat();
      const eventCount = ev.filter((event) => {
        return moment(event.start_date).format('YYYY-MM-DD') === moment(selectedDate).format('YYYY-MM-DD')
      });
      if (eventCount.length > 1) {
        setSbtitle(`${eventCount.length} agendamentos marcados`);
      }
      else if (eventCount.length === 1) {
        setSbtitle(`${eventCount.length} agendamento marcado`);
      } else {
        setSbtitle('Nenhum agendamento marcado');
      }
    }
  }, [persons, loaded, selectedDate])

  function renderCards() {
    var cards = [];
    var per = persons || [];
    const dt = moment(dateRedux);
    for (let i = 0; i < 24; i++) {
      cards.push(
        <div key={i}
          className="schedule-card"
          onClick={() => {
            const date_copy = moment(dateRedux)
            date_copy.set('hour', i);
            if (date_copy.isBefore(moment()) !== true) {
              dispatch(setSelectedDate(date_copy.format('YYYY-MM-DD HH:mm')));
              dispatch(setSelectedAppointment(undefined));
              dispatch(setEdit(false));
              dispatch(setScheduleFormVisible(true));
            } else {
              setToastVisible(true);
              setTimeout(() => {
                setToastVisible(false);
              }, 4000);
            }
          }}>
          <div className="d-flex align-items-center">
            <div className="hour ml-4">{i}:00</div>
            {
              per.map(personEvents => (
                personEvents.appointments.filter(appointment => isSameDayAndHour(appointment, appointment.start_date, dt.format('D'), i.toString())).map(a =>
                  <div key={a.id} className='m-2'>
                    <Badge pill bg={getBadgeBackgroundColor(a)} text={getTextColor(a)}
                      onClick={(e) => {
                        e.stopPropagation();
                        dispatch(setSelectedAppointment(a));
                        dispatch(setEdit(true));
                        dispatch(setScheduleFormVisible(true));
                      }}>
                      {personEvents.email === loggedUser.email ? "" : '(' + personEvents.name.split(' ')[0] + ')'} {a.description} (até {moment(a.end_date).format('HH:mm')})
                    </Badge>
                  </div>)
              ))
            }
          </div>
        </div>
      );
    }
    return cards;
  }

  function changeDay(delta){
    const newDate = moment(selectedDate);
    newDate.add(delta, 'days');
    dispatch(setSelectedDate(newDate.format('YYYY-MM-DD HH:mm')));
    dispatch(setEventsLoaded(false));
  }

  return (
    <div className="d-flex flex-column w-100 h-100">
      <ToastContainer position="top-start" className="p-3 position-fixed top-0 mt-5">
        <Toast show={toastVisible} onClose={() => setToastVisible(false)}>
          <Toast.Header>
            <strong className="me-auto">Informação</strong>
          </Toast.Header>
          <Toast.Body>Não é possível marcar um evento novo no passado!</Toast.Body>
        </Toast>
      </ToastContainer>      
      <div id='schedule-title-div' className="d-flex mx-auto flex-row w-100 justify-content-between sticky-header">
        <ArrowButton direction="left" onClick={() => {changeDay(-1)}} />
        <div className="d-flex flex-column align-self-center align-items-center">
          <p className="fs-4 fw-bold text-muted mb-0 prevent-select">{refDateString(moment(selectedDate))}</p>
          <p className="fs-6 fw-semibold text-muted prevent-select">{subtitle}</p>
        </div>
        <ArrowButton direction="right" onClick={() => {changeDay(1)}} />

      </div>
      {renderCards()}
      {children}
    </div>
  );
};

export default ScheduleList;

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedUsers } from '../../services/slices/selectionsSlice';
import { setEventsLoaded } from '../../services/slices/useEventsSlice';

import './calendarCompareOverlay.css';

export default function CalendarCompareOverlay() {

    const dispatch = useDispatch();

    const selectedUsers = useSelector((state) => state.selections.selectedUsers);
    const names = selectedUsers.map((user) => user.name.split(' ')[0]);

    return (
        <div className='fixed-bottom text-center visitDiv'>
            <p style={{ fontSize: "18px" }} className='h4 font-weight-bold py-2 text-center my-2 text-dark sub'>Você está comparando o calendário de <b>{names.join(', ')}</b> com o seu</p>
            <button className='btnBlue' onClick={() => {
                dispatch(setSelectedUsers([]))
                dispatch(setEventsLoaded(false));

            }}>Cancelar</button>
        </div>
    )
}
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { youtanEmailValidator } from '../../services/functions';
import useAxios from '../../services/hooks/useAxios';
import { setLoading } from '../../services/slices/loadingSlice';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';

function SignupPage({ utils }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const axios = useAxios();

    function validatePassword(password) {
    return password.length >= 8 && /[a-z]/.test(password) && /[A-Z]/.test(password) && /\d/.test(password);
  }

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            password1: '',
            password2: ''
        },
        validate: values => {
            let errors = {};
            if (!values.email || !youtanEmailValidator(values.email)) {
                errors.email = 'O domínio de e-mail não é permitido';
            }
            if (!validatePassword(values.password1)) {
                errors.password1 = 'A senha deve ter no mínimo 8 caracteres, uma letra maiúscula, um número e um símbolo';
            }
            if (values.password2 !== values.password1) {
                errors.password2 = 'As senhas não conferem';
            }
            return errors;
        },
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            dispatch(setLoading(true));
            const { password1, password2,...rest } = values;
            const valuesToSend = {...rest, password: password1 };
            try {
                const response = await axios.post('users/signup', valuesToSend);
                dispatch(setLoading(false));
                utils.showAlert('Cadastro realizado com sucesso', 'ok', gotoHome);
            } catch (err) {
                console.error(err);
                dispatch(setLoading(false));
                if (err.response.status === 400) {
                    utils.showAlert('Domínio de e-mail não autorizado', 'error');
                } else if (err.response.status === 409) {
                    utils.showAlert('E-mail já cadastrado', 'error');
                } else {
                    utils.showAlert('Ocorreu um erro. Tente mais tarde', 'error');
                }
            }
        }
    });

    function gotoHome() {
        utils.hideAlert();
        navigate('/home');
    }

    return (
        <div className='whiteBackground'>
            <div className='box d-flex flex-column align-items-center centerscreen boxshadow'>
                <p className='h4 font-weight-bold py-2 text-center my-2 text-dark'> Cadastro </p>
                <Form onSubmit={formik.handleSubmit} className='formLimit'>
                    <Form.Group className="mb-3" controlId="formGroupName">
                        <Form.Label>Nome completo</Form.Label>
                        <Form.Control required name='name' placeholder="Insira seu nome" onChange={formik.handleChange} value={formik.values.name} />
                        {formik.errors.name && <Form.Label as="span" className="text-danger">{formik.errors.name}</Form.Label>}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupEmail">
                        <Form.Label>E-mail</Form.Label>
                        <Form.Control required name='email' placeholder="Insira seu e-mail" onChange={formik.handleChange} value={formik.values.email} />
                        {formik.errors.email && <Form.Label as="span" className="text-danger">{formik.errors.email}</Form.Label>}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupPassword1">
                        <Form.Label>Senha</Form.Label>
                        <Form.Control required name='password1' type="password" autoComplete="off" placeholder="Insira sua senha" onChange={formik.handleChange} value={formik.values.password1} />
                        {formik.errors.password1 && <Form.Label as="span" className="text-danger">{formik.errors.password1}</Form.Label>}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formGroupPassword2">
                        <Form.Label>Confirme a Senha</Form.Label>
                        <Form.Control required name='password2' type="password" autoComplete="off" placeholder="Confirme sua senha" onChange={formik.handleChange} value={formik.values.password2} />
                        {formik.errors.password2 && <Form.Label as="span" className="text-danger">{formik.errors.password2}</Form.Label>}
                    </Form.Group>
                    <div className='d-flex flex-column align-items-center'>
                        <button type='submit' disabled={!formik.isValid || !formik.dirty} className='btnBlue formBtnSize'>Cadastrar</button>
                        <button type='button' onClick={() => { navigate('/') }} className='btnBorderlessBlue formBtnSize' >Já tenho uma conta</button>
                    </div>
                </Form>
            </div>
        </div>
    );
}

export default SignupPage;

import { useRef, useEffect, useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import UserInitialCircle from '../userInnitial/userinitial';
import CalendarButtons from '../calendarButtons/calendarButtons';
import Badge from 'react-bootstrap/Badge';
import logo from './logo.png'
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import './navbar.css';

export default function NavBar(props) {

    const [isNavbarVisible, setIsNavbarVisible] = useState(true);
    const lastScrollTop = useRef(0);
    const navbarRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const isNearTop = currentScrollTop < 50; // Adjust this value as needed

            if (currentScrollTop > lastScrollTop.current) {
                // Scrolling down
                setIsNavbarVisible(false);
            } else if (isNearTop) {
                // Scrolling up and near the top
                setIsNavbarVisible(true);
            }
            lastScrollTop.current = currentScrollTop <= 0 ? 0 : currentScrollTop; // For Mobile or negative scrolling
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const user = useSelector((state) => state.selections.loggedUser);

    
    useEffect(() => {
      const navbarHeight = navbarRef.current.offsetHeight;
    //   document.querySelector('.navbar-filler').style.height = `${navbarHeight}px`;
    }, [user]);    

    return (
        <div>
            <Navbar ref={navbarRef} fixed="top" className={`prevent-select navbarStyle ${isNavbarVisible ? 'visible' : 'hidden'}`} variant="dark">
                <Link to='/home' style={{textDecoration: 'none'}}>
                    <div className='d-flex align-items-center'>
                        <img src={logo} alt="logo" className='logo' />
                        <Navbar.Brand className='navtitle' >Calendário</Navbar.Brand>
                    </div>
                </Link>
                <Badge pill className='hideBadge'  bg="danger">mvp</Badge>

                <Nav className="me-auto">
                    {/* <Nav.Link className='navitemtext' href="/home">Editais</Nav.Link> */}
                </Nav>

                <div style={{marginRight: '20px'}}>
                    { window.location.pathname === '/home' ?
                        <CalendarButtons view={props.iconView || 'day'} usersClick={props.usersClick}/>
                        :
                        <div/>
                    }
                </div>
                <UserInitialCircle 
                    id={0} 
                    name={user?.name || ""}
                    photo={user?.profile_picture}
                    dropdown={true} 
                    allowUpload={false} 
                />

            </Navbar>
            {/* <div  className="navbar-filler" style={{ width: '100%', background: 'transparent', zIndex: -1 }} /> */}
        </div>

    )
}
